import React from "react";
import { Route } from "react-router-dom";

import { AmenitiesForm } from "./fields/AmenitiesForm";
import { AvailabilityRules } from "./fields/AvailabilityRules";
import { CheckoutInstructions } from "./fields/CheckoutInstructions";
import { ItemLocation } from "./fields/CleaningItemLocation";
import { SpecialTasks } from "./fields/CleaningSpecialTasks";
import { Waste } from "./fields/CleaningWaste";
import { ConfigurationBeds } from "./fields/ConfigurationBeds";
import { ConfigurationDoorsAndKeys } from "./fields/ConfigurationDoorsAndKeys";
import { ConfigurationSize } from "./fields/ConfigurationSize";
import { Description } from "./fields/Description";
import { InternalHostNotes } from "./fields/InternalHostNotes";
import { ListingGalleryAirbnb } from "./fields/ListingGalleryAirbnb";
import { ListingGalleryGuesty } from "./fields/ListingGalleryGuesty";
import { ListingPreferences } from "./fields/ListingPreferences";
import { ListingSync } from "./fields/ListingSync";
import { OperateBoiler } from "./fields/OperateBoiler";
import { Emergency } from "./fields/OperateEmergency";
import { Kitchen } from "./fields/OperateKitchen";
import { Maintenance } from "./fields/OperateMaintenance";
import { Wifi } from "./fields/OperateWiFi";
import { PropertyAccess } from "./fields/PropertyAccess";
import { PropertyCheckin } from "./fields/PropertyCheckIn";
import { PropertyLocation } from "./fields/PropertyLocation";
import { PropertyNoise } from "./fields/PropertyNoise";
import { OtherNotes } from "./fields/PropertyOtherNotes";
import {
  LISTING_SYNC,
  LOCATION,
  LISTING_PHOTOS,
  LISTING_PHOTOS_GUESTY,
  ACCESS,
  CHECK_IN,
  NOISE,
  OTHER_NOTES,
  DESCRIPTION,
  SIZE,
  DOORS_AND_KEYS,
  BEDS,
  WIFI,
  MAINTENANCE,
  KITCHEN,
  EMERGENCY,
  KITCHEN_AND_DINING,
  ENTERTAINMENT,
  PARKING_AND_FACILITIES,
  BEDROOM_AND_LAUNDRY,
  SAFETY,
  ITEM_LOCATION,
  WASTE_AND_RECYCLING,
  SPECIAL_TASKS,
  INTERNAL,
  BATHROOM,
  INTERNET_AND_OFFICE,
  FAMILY,
  HEATING_AND_COOLING,
  AMENITIES_LOCATION,
  AMENITIES_OUTDOOR,
  ACCESSIBILITY_BATHROOM,
  ACCESSIBILITY_EQUIPMENT,
  ACCESSIBILITY,
  SERVICES,
  AVAILABILITY_RULES,
  LISTING_PREFERENCES,
  OPERATE_BOILER,
  CHECKOUT_INSTRUCTIONS
} from "./sections";

export const HomeInfoRoutes = () => (
  <>
    <Route path={`/${LISTING_SYNC}`} component={ListingSync} />
    <Route path={`/${LISTING_PHOTOS}`} component={ListingGalleryAirbnb} />
    <Route
      path={`/${LISTING_PHOTOS_GUESTY}`}
      component={ListingGalleryGuesty}
    />
    <Route path={`/${LOCATION}`} component={PropertyLocation} />
    <Route path={`/${ACCESS}`} component={PropertyAccess} />
    <Route path={`/${CHECK_IN}`} component={PropertyCheckin} />
    <Route path={`/${NOISE}`} component={PropertyNoise} />
    <Route path={`/${OTHER_NOTES}`} component={OtherNotes} />
    <Route path={`/${DESCRIPTION}`} component={Description} />
    <Route
      path={`/${CHECKOUT_INSTRUCTIONS}`}
      component={CheckoutInstructions}
    />
    <Route path={`/${SIZE}`} component={ConfigurationSize} />
    <Route path={`/${DOORS_AND_KEYS}`} component={ConfigurationDoorsAndKeys} />
    <Route path={`/${BEDS}`} component={ConfigurationBeds} />
    <Route path={`/${WIFI}`} component={Wifi} />
    <Route path={`/${MAINTENANCE}`} component={Maintenance} />
    <Route path={`/${OPERATE_BOILER}`} component={OperateBoiler} />
    <Route path={`/${EMERGENCY}`} component={Emergency} />
    <Route path={`/${KITCHEN}`} component={Kitchen} />
    <Route
      path={`/${KITCHEN_AND_DINING}`}
      render={() => (
        <AmenitiesForm
          category="kitchen_and_dining"
          title="Kitchen and dining"
        />
      )}
    />
    <Route
      path={`/${ENTERTAINMENT}`}
      render={() => (
        <AmenitiesForm
          category="entertainment"
          title="Entertainment and office"
        />
      )}
    />
    <Route
      path={`/${PARKING_AND_FACILITIES}`}
      render={() => (
        <AmenitiesForm
          category="parking_and_facilities"
          title="Parking and Facilities"
        />
      )}
    />
    <Route
      path={`/${BEDROOM_AND_LAUNDRY}`}
      render={() => (
        <AmenitiesForm
          category="bedroom_and_laundry"
          title="Bedroom and laundry"
        />
      )}
    />
    <Route
      path={`/${SAFETY}`}
      render={() => <AmenitiesForm category="safety" title="Safety" />}
    />
    <Route
      path={`/${BATHROOM}`}
      render={() => <AmenitiesForm category="bathroom" title="Bathroom" />}
    />
    <Route
      path={`/${INTERNET_AND_OFFICE}`}
      render={() => (
        <AmenitiesForm
          category="internet_and_office"
          title="Internet and Office"
        />
      )}
    />
    <Route
      path={`/${FAMILY}`}
      render={() => <AmenitiesForm category="family" title="Family" />}
    />
    <Route
      path={`/${HEATING_AND_COOLING}`}
      render={() => (
        <AmenitiesForm
          category="heating_and_cooling"
          title="Heating and cooling"
        />
      )}
    />
    <Route
      path={`/${AMENITIES_LOCATION}`}
      render={() => <AmenitiesForm category="location" title="Location" />}
    />
    <Route
      path={`/${AMENITIES_OUTDOOR}`}
      render={() => <AmenitiesForm category="outdoor" title="Outdoor" />}
    />
    <Route
      path={`/${ACCESSIBILITY}`}
      render={() => (
        <AmenitiesForm
          category="accessibility_getting_home"
          title="Accessibility"
        />
      )}
    />
    <Route
      path={`/${ACCESSIBILITY_BATHROOM}`}
      render={() => (
        <AmenitiesForm
          category="accessibility_bathroom"
          title="Accessibility Bathroom"
        />
      )}
    />
    <Route
      path={`/${ACCESSIBILITY_EQUIPMENT}`}
      render={() => (
        <AmenitiesForm
          category="accessibility_equipment"
          title="Accessibility Equipment"
        />
      )}
    />
    <Route
      path={`/${SERVICES}`}
      render={() => <AmenitiesForm category="services" title="Services" />}
    />
    <Route path={`/${AVAILABILITY_RULES}`} component={AvailabilityRules} />
    <Route path={`/${LISTING_PREFERENCES}`} component={ListingPreferences} />
    <Route path={`/${ITEM_LOCATION}`} component={ItemLocation} />
    <Route path={`/${WASTE_AND_RECYCLING}`} component={Waste} />
    <Route path={`/${SPECIAL_TASKS}`} component={SpecialTasks} />
    <Route path={`/${INTERNAL}`} component={InternalHostNotes} />
  </>
);
