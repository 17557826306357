import get from "lodash/get";

import AIRBNB_SYNC_REQUIRED_FIELDS from "./airbnbSyncRequiredFields";
import { SIDEBAR_PATHS } from "./sections";

const redirect = (history, path) => {
  history.push(path);
};

export const handleNavConfirm = ({
  history,
  isModified,
  path,
  setIsModified,
  setSidebarDraft,
  sidebarDraft
}) => {
  const sidebarName = Object.entries(SIDEBAR_PATHS).find(([, paths]) =>
    paths.includes(path)
  )?.[0];

  const sidebarChange = sidebarDraft?.name
    ? sidebarName !== sidebarDraft.name
    : false;

  if (isModified || sidebarChange) {
    if (window.confirm("Leave the page without saving?")) {
      setIsModified(false);
      setSidebarDraft({});
      redirect(history, path);
    }
  } else {
    redirect(history, path);
  }
};

export const prepareAmenityValuesForBackend = (values, assets) => {
  const prepared = values
    .map((amenity) => {
      const pendingAsset = assets?.find(
        ({ amenityTypeId }) =>
          amenityTypeId === amenity.home_info_amenity_type_id
      )?.pendingAsset?.fields;

      if (amenity.available) {
        // amenity existed before and was edited
        if (amenity.home_info_id) {
          return {
            id: amenity.amenity_id,
            external_notes: amenity.external_notes,
            internal_notes: amenity.internal_notes,
            deleted_at: null,
            property_file_attributes: pendingAsset
              ? {
                  property_id: pendingAsset.propertyId,
                  file_name: pendingAsset.fileName,
                  file_size: pendingAsset.fileSize,
                  folder_name: pendingAsset.folderName,
                  content_type: pendingAsset.contentType,
                  s3_object_key: pendingAsset.key
                }
              : undefined
          };
        }
        // new amenity
        return {
          external_notes: amenity.external_notes,
          internal_notes: amenity.internal_notes,
          home_info_amenity_type_id: amenity.home_info_amenity_type_id,
          property_file_attributes: pendingAsset
            ? {
                property_id: pendingAsset.propertyId,
                file_name: pendingAsset.fileName,
                file_size: pendingAsset.fileSize,
                folder_name: pendingAsset.folderName,
                content_type: pendingAsset.contentType,
                s3_object_key: pendingAsset.key
              }
            : undefined
        };
      }

      // amenity existed but was set to unavailable
      if (amenity.home_info_id && amenity.deleted_at == null) {
        return {
          id: amenity.amenity_id,
          external_notes: amenity.external_notes,
          internal_notes: amenity.internal_notes,
          deleted_at: new Date(Date.now())
        };
      }

      return null;
    })
    .filter((el) => el !== null);

  return {
    home_info_amenities_attributes: prepared
  };
};

// values - currently viewed existing amenities
export const prepareAmenitiesData = (types, values) => {
  const ready = types.map((type) => {
    const elementOfType = values.find(
      (el) => el.home_info_amenity_type_id === type.id
    );

    const { id: home_info_amenity_type_id, name, key } = type;

    if (elementOfType) {
      return {
        home_info_amenity_type_id,
        name,
        key,
        ...elementOfType,
        available: !elementOfType.deleted_at,
        expanded: false,
        amenity_id: elementOfType.id
      };
    }

    return {
      home_info_amenity_type_id,
      name,
      key,
      available: false,
      expanded: false
    };
  });

  return ready;
};

export const hasAirbnbRequiredFields = (
  numberOfPhotos = 0,
  fields,
  requiredFields = AIRBNB_SYNC_REQUIRED_FIELDS
) => {
  const allFields = Object.values(requiredFields).flat();

  const listingPhotosReady = fields?.property_attributes?.listing_photos_ready;

  return allFields.every((element) => {
    if (element === "listing_photos") {
      return !(listingPhotosReady && numberOfPhotos < 1);
    }

    const value = get(fields, element, null);

    if (typeof value === "string" || element === "beds_attributes") {
      return value.length > 0;
    }

    return typeof value !== "undefined" && value !== null;
  });
};

export const getChangedValues = (values, initialValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};
