import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import {
  ACCEPTED_CONTENT_TYPES,
  ERROR_FILE_INVALID_TYPE,
  ERROR_FILE_TOO_LARGE
} from "../../constants";
import { selectUploadQueue } from "../../data/upload/selectors";
import { uploadFiles } from "../../data/upload/thunks";
import { imageFromFile } from "../../helpers";
import { UploadProgressModalContainer } from "../UploadProgressModalContainer";
import { renameFiles } from "./renameFiles";
import { UploadPlaceholder } from "./UploadPlaceholder";

// Prepare Image object for each uploaded file
// So that validator can access image dimensions
const getFilesFromEvent = async ({ dataTransfer, target }) =>
  Promise.all(
    Array.from(target.files || dataTransfer.files).map(async (file) => {
      if (!file.type.startsWith("image/")) {
        return file;
      }

      return Object.defineProperty(file, "image", {
        value: await imageFromFile(file)
      });
    })
  );

const handleFileRejections = (
  fileRejections,
  acceptContentTypes,
  maxSizeFormatted
) => {
  const errors = [];

  fileRejections.forEach((file) => {
    file.errors.forEach((err) => {
      const trimmedFileName =
        file.file.name.length > 20
          ? `${file.file.name.slice(0, 17)}...${file.file.name.slice(
              file.file.name.lastIndexOf(".")
            )}`
          : file.file.name;

      if (err.code === ERROR_FILE_TOO_LARGE) {
        errors.push(
          `Error uploading file ${trimmedFileName}. File size cannot exceed ${maxSizeFormatted}.`
        );
      } else if (err.code === ERROR_FILE_INVALID_TYPE) {
        errors.push(
          `Error uploading file ${trimmedFileName}. File type must be one of ${acceptContentTypes.join(
            ", "
          )}.`
        );
      } else {
        errors.push(`Error for file ${trimmedFileName}. ${err.message}`);
      }
    });
  });
  return errors;
};

const portraitValidator = (file) => {
  if (!file.type.match("image/*") || !file.image) {
    return null;
  }

  if (file.image.height > file.image.width) {
    return {
      code: "portrait_not_allowed",
      message: "Portrait images are not allowed."
    };
  }

  return null;
};

export const UploadDropZoneContainer = ({
  children,
  acceptContentTypes = ACCEPTED_CONTENT_TYPES,
  portraitNotAllowed = false,
  maxSize
}) => {
  const dispatch = useDispatch();

  const showUploadProgress = useSelector(
    (state) => selectUploadQueue(state).length > 0
  );

  const [errors, setErrors] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const newErrors = handleFileRejections(
        fileRejections,
        acceptContentTypes,
        maxSize?.formattedSize
      );

      setErrors(newErrors);

      dispatch(uploadFiles(renameFiles(acceptedFiles)));
    },
    [acceptContentTypes, dispatch, maxSize?.formattedSize]
  );

  const {
    getRootProps,
    getInputProps,
    open: handleSelectFiles,
    isDragActive
  } = useDropzone({
    getFilesFromEvent,
    validator: portraitNotAllowed ? portraitValidator : null,
    onDrop,
    accept: acceptContentTypes.join(", "),
    ...(maxSize && { maxSize: maxSize.size })
  });

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
      className={classNames("file-list", { dragging: isDragActive })}
    >
      {errors.map((error, index) => (
        <div key={index} className="invalid-file">
          <i className="fa fa-exclamation-circle p-2" />
          <span>{error}</span>
        </div>
      ))}

      <div className="d-flex flex-wrap justify-content-start">
        <UploadPlaceholder
          onSelectFiles={handleSelectFiles}
          inputProps={getInputProps()}
          maxSizeText={
            maxSize?.formattedSize && `Max ${maxSize.formattedSize} per photo`
          }
        />
        {children}

        {showUploadProgress && <UploadProgressModalContainer errors={errors} />}
      </div>
    </div>
  );
};
