const {
  FOLDER_NAME_LISTING,
  FOLDER_NAME_AIRBNB,
  FOLDER_NAME_GUESTY,
  FOLDER_NAME_ACCESSIBILITY,
  FOLDER_NAME_APPLIANCES,
  FOLDER_NAME_DAMAGES,
  FOLDER_NAME_ENTRANCE,
  FOLDER_NAME_HEATING,
  FOLDER_NAME_HOST,
  FOLDER_NAME_LOCKBOX,
  FOLDER_NAME_OTHER
} = window.AirbaseConstants.PropertyFile;

export const DEFAULT_FOLDER = null;

export const FOLDERS = [
  { name: FOLDER_NAME_APPLIANCES, path: "/appliances" },
  { name: FOLDER_NAME_LOCKBOX, path: "/lockbox" },
  { name: FOLDER_NAME_HEATING, path: "/heating" },
  { name: FOLDER_NAME_ENTRANCE, path: "/entrance" },
  { name: FOLDER_NAME_LISTING, path: "/listing" },
  { name: FOLDER_NAME_AIRBNB, path: "/airbnb" },
  { name: FOLDER_NAME_GUESTY, path: "/guesty" },
  { name: FOLDER_NAME_ACCESSIBILITY, path: "/accessibility" },
  { name: FOLDER_NAME_DAMAGES, path: "/damages" },
  { name: FOLDER_NAME_HOST, path: "/host" },
  { name: FOLDER_NAME_OTHER, path: "/other" }
];

export const ACCEPTED_CONTENT_TYPES = [
  "image/*",
  "audio/*",
  "video/*",
  "application/pdf",
  "application/msword",
  "application/zip",
  "application/x-iwork-keynote-sffkey",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

export const ACCEPTED_AIRBNB_PHOTOS_CONTENT_TYPES = [
  "image/jpeg",
  "image/png",
  "image/bmp"
];

export const AIRBNB_PHOTO_MAX_SIZE = {
  size: 25000000,
  formattedSize: "25MB"
};
export const GUESTY_PHOTO_MAX_SIZE = {
  size: 4000000,
  formattedSize: "4MB"
};

export const ERROR_FILE_TOO_LARGE = "file-too-large";
export const ERROR_FILE_INVALID_TYPE = "file-invalid-type";
